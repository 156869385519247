import React, { useRef, useEffect, useState } from "react";
import { CssBaseline, Box, Container, Grid } from "@mui/material";
import { useTheme } from "@emotion/react";
import PlayersEndScreen from "../PlayersEndScreen/PlayersEndScreen";
import { useSelector } from "react-redux";

import Confetti from "react-confetti";

const EndScreen = () => {
    const endScreenContainer = useRef();
    const theme = useTheme();
    const [endScreenHeight, setEndScreenHeight] = useState(0);

    const players = useSelector(({ game }) => {
        return game.gamePlayers;
    });

    useEffect(() => {
        setEndScreenHeight(endScreenContainer.current.clientHeight);
    }, [endScreenHeight]);

    return (
        <>
            <CssBaseline />
            <Container
                maxWidth="xl"
                sx={{
                    padding: "0",
                    height: "auto",
                }}
                ref={endScreenContainer}
            >
                <Grid
                    container
                    sx={{
                        ml: 0,
                        pt: { xs: "0", md: 4 },
                    }}
                    spacing={0}
                >
                    <Grid item xs={12}>
                        <PlayersEndScreen />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default EndScreen;
