/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Box, Typography, CssBaseline, Container, Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Players from "../Players/Players";
import styles from "./Play.module.css";
import { useTheme } from "@mui/material/styles";

import useMediaQuery from "@mui/material/useMediaQuery";
import {
    SOCKET_RECEIVE_EVENTS,
    GAME_STATUS,
    SOCKET_ACTIONS,
} from "../../helpers/constant";
import { useNavigate } from "react-router-dom";
import { addTimer } from "../../reducers/TimerReducer";
import {
    updateMaxSeconds,
    updateQuestion,
} from "../../reducers/QuestionsReducer";
import { playerReset, populatePlayers } from "../../reducers/GameReducer";
import { updateCorrectAnswer } from "../../reducers/AnswersReducer";
import useSocketEvents from "../../hooks/useSocketEvents";

import MultipleAnswers from "../MultipleAnswers/MultipleAnswers";
import TfAnswers from "../TfAnswers/TfAnswers";
import ErrorPage from "../ErrorPage/ErrorPage";
import MessageWaitingCountdown from "../MessageWaitingCountdown/MessageWaitingCountdown";

import NextVideo from "../NextVideo/NextVideo";
import LobbyLeaderboard from "../LobbyLeaderboard/LobbyLeaderboard";
import Timer from "../Timer/Timer";

const Play = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const matches = useMediaQuery("(min-width:400px)");

    const answerContainerRef = useRef();

    const questionObj = useSelector(({ question }) => question.questionObject);
    const currentVideo = useSelector(({ question }) => question.currentVideo);

    const questionCount = useSelector(
        ({ question }) => question.questionsCount
    );
    const questionIndex = useSelector(({ question }) => question.index);

    const correctAnswer = useSelector(({ answers }) => answers.correctAnswer);
    const gameStatus = useSelector(({ game }) => game.gameStatus);

    const [selectedAns, setSelectedAns] = useState(null);

    const [buttonBackgroundColor, setButtonBackgroundColor] = useState(
        theme.palette.buttonColor
    );
    const error = useSelector(({ message }) => message.error);
    const waitingCountdown = useSelector(
        ({ message }) => message.messageWaitingCountdown
    );

    const maxSeconds = useSelector(({ question }) => question.maxSeconds);

    // check gameStatus and redirect to end page when game is over
    useEffect(() => {
        if (
            gameStatus === GAME_STATUS.WAITING ||
            gameStatus === GAME_STATUS.COUNTDOWN
        ) {
            navigate("/");
        }

        if (gameStatus === GAME_STATUS.END) {
            let message = { action: "endGame" };
            // method to send message to parent outside iframe
            window.parent.postMessage(message, "*");
            navigate("/end");
        }
    }, [gameStatus, navigate]);

    // receive in-game // question timer
    const handleRoundCountdown = useCallback(
        (questionTimer) => {
            dispatch(addTimer(questionTimer));
        },
        [dispatch]
    );

    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.ROUND_COUNTDOWN,
        null,
        handleRoundCountdown
    );

    // receive showNextQuestion event
    const handleRoundStarts = useCallback(
        (questionIndex, maxSeconds, speech) => {
            dispatch(playerReset(["didAnswer", "isCorrect"]));

            dispatch(updateCorrectAnswer(undefined));
            dispatch(updateMaxSeconds(maxSeconds));
            dispatch(updateQuestion(questionIndex));
            // console.log("speech", speech);
            if (speech !== undefined && speech !== null) {
                let sound = new Audio(speech);
                sound.play();
            }
        },
        [dispatch]
    );

    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.ROUND_STARTS,
        null,
        handleRoundStarts
    );

    // receive round ends event
    const handleRoundEnds = useCallback(
        ({ correctAnswer, playersStats }) => {
            dispatch(updateCorrectAnswer(correctAnswer));
            dispatch(populatePlayers(playersStats));
        },
        [dispatch]
    );

    // when round ends
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.ROUND_ENDS,
        null,
        handleRoundEnds
    );

    return (
        questionObj !== undefined &&
        (error === "" && waitingCountdown === "" ? (
            <>
                <CssBaseline />
                <Container
                    className={styles.backgroundColor}
                    sx={{
                        padding: "0",
                        width: "100% !important",
                        maxWidth: "100% !important",
                        paddingLeft: {
                            lg: "10%",
                            xl: theme.mainContainer.paddingLeft,
                        },
                        paddingRight: {
                            lg: "10%",
                            xl: theme.mainContainer.paddingRight,
                        },
                        height: theme.mainContainer.height,
                    }}
                >
                    <Grid
                        container
                        sx={{
                            height: "100%",
                        }}
                        spacing="0"
                    >
                        <Grid item xs={2}>
                            <Box
                                sx={{
                                    height: "100%",
                                    width: "100%",
                                    overflow: "hidden",
                                    paddingBottom: theme.px10.main,
                                }}
                            >
                                <Players
                                    direction="column"
                                    alignItems="flex-start"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={7} sx={{ height: "100%" }}>
                            <Box
                                sx={{
                                    position: "relative",
                                    height: "100%",
                                    paddingLeft: "0px",
                                    paddingRight: "16px;",
                                }}
                            >
                                <Box
                                    sx={{
                                        marginTop: "0px",
                                        height: "auto",
                                        px: 0,
                                        py: 0,
                                        position: "relative",
                                        border: `3px solid ${theme.palette.wmColor.main}`,
                                        borderRadius: `${theme.borderRadius.primary}`,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            height: "auto",
                                            px: 0,
                                            py: 0,
                                            position: "relative",
                                        }}
                                    >
                                        <img
                                            src={`${
                                                currentVideo
                                                    ? currentVideo.image
                                                    : ""
                                            }`}
                                            srcSet={`${
                                                currentVideo
                                                    ? currentVideo.image
                                                    : ""
                                            }`}
                                            alt={
                                                currentVideo
                                                    ? currentVideo.image
                                                    : ""
                                            }
                                            loading="lazy"
                                            style={{
                                                width: "100%",
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            height: "100%",
                                            width: "100%",
                                            position: "absolute",
                                            top: 0,
                                            backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.7))`,
                                        }}
                                    >
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                color: "#FFFFFF",
                                                fontSize: 32,
                                                textTransform: "capitalize",
                                                position: "absolute",
                                                bottom: "0px",
                                                left: "0px",
                                                padding: "100px 20px 20px",
                                                textShadow: "1px 1px 1px black",
                                                lineHeight: "35px",
                                                fontWeight: 700,
                                            }}
                                        >
                                            {questionObj
                                                ? questionObj.question
                                                : ""}
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box
                                    id="answerContainer"
                                    sx={{
                                        paddingTop: "16px",
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "100%",
                                        position: "relative",
                                        gap: "12px",
                                    }}
                                    ref={answerContainerRef}
                                >
                                    {questionObj.type === "multi" && (
                                        <MultipleAnswers
                                            questionObj={questionObj}
                                            buttonBackgroundColor={
                                                buttonBackgroundColor
                                            }
                                            correctAnswer={correctAnswer}
                                            matches={matches}
                                            selectedAns={selectedAns}
                                        />
                                    )}
                                    {/* if true or false question type */}
                                    {questionObj.type === "tf" && (
                                        <TfAnswers
                                            questionObj={questionObj}
                                            buttonBackgroundColor={
                                                buttonBackgroundColor
                                            }
                                            correctAnswer={correctAnswer}
                                            selectedAns={selectedAns}
                                        />
                                    )}
                                </Box>
                                {/* 3. end of answer box */}
                                <Box className={styles.timer}>
                                    <Timer
                                        preLabel="Answer to be revealed in"
                                        maxSeconds={maxSeconds}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            {questionIndex + 1 === questionCount ? (
                                <NextVideo />
                            ) : (
                                <LobbyLeaderboard topPadding="16px" />
                            )}
                        </Grid>
                    </Grid>
                </Container>
            </>
        ) : error !== "" ? (
            <ErrorPage />
        ) : (
            <MessageWaitingCountdown />
        ))
    );
};

export default Play;
