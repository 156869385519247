import React from "react";
import { useSelector } from "react-redux";
import {
    Avatar,
    Box,
    CardHeader,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import lobbyLeaderboardCSS from "./LobbyLeaderboard.module.css";
import { formatScore } from "../../helpers/Global";

const LobbyLeaderboard = ({ topPadding }) => {
    const theme = useTheme();
    const currentVideo = useSelector(({ question }) => question.currentVideo);

    const rows = currentVideo ? currentVideo.leaderboard : [];

    return (
        <>
            {rows.length !== 0 && (
                <Box
                    sx={{
                        height: "auto",
                        width: { xs: "100%" },
                        overflow: "hidden",
                        marginTop: topPadding
                            ? topPadding
                            : theme.size.distanceFromTop,
                        paddingBottom: theme.px10.main,
                        paddingLeft: "16px",
                        backgroundColor: theme.palette.backgroundColor.main,
                    }}
                >
                    <Typography
                        noWrap
                        variant="body1"
                        sx={{
                            textTransform: "uppercase",
                            fontWeight: 500,
                            color: "#00B3E2",
                            fontSize: "17px",
                        }}
                    >
                        TOP 10 TRIVA SCORE
                    </Typography>
                    <Table size="small">
                        <TableBody>
                            {rows.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell
                                        className={lobbyLeaderboardCSS.tdBorder}
                                        sx={{
                                            color: "white",
                                            fontWeight: 900,
                                            textAlign: "right",
                                        }}
                                    >
                                        {index + 1}
                                    </TableCell>
                                    <TableCell
                                        className={lobbyLeaderboardCSS.tdBorder}
                                        sx={{
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                            color: "#FFFFFF",
                                        }}
                                    >
                                        <CardHeader
                                            sx={{
                                                paddingTop: 0,
                                                paddingBottom: 0,
                                            }}
                                            avatar={
                                                row.isBlank ? (
                                                    <Avatar
                                                        className={
                                                            lobbyLeaderboardCSS.avatarCSS
                                                        }
                                                    >
                                                        {row.avatar}
                                                    </Avatar>
                                                ) : (
                                                    <Avatar
                                                        className={
                                                            lobbyLeaderboardCSS.avatarCSS
                                                        }
                                                        alt={row.name}
                                                        src={`${row.avatar}`}
                                                    />
                                                )
                                            }
                                            title={row.name}
                                        />
                                    </TableCell>
                                    <TableCell
                                        className={lobbyLeaderboardCSS.tdBorder}
                                        sx={{
                                            color: "white",
                                            fontWeight: 900,
                                        }}
                                    >
                                        <Typography
                                            noWrap
                                            variant="body1"
                                            sx={{
                                                textTransform: "uppercase",
                                                fontWeight: 500,
                                                color: "#FFFFFF",
                                                fontSize: "16px",
                                                textAlign: "center",
                                            }}
                                        >
                                            {formatScore(row.score)}
                                        </Typography>
                                        <Typography
                                            noWrap
                                            variant="body1"
                                            sx={{
                                                textTransform: "uppercase",
                                                fontWeight: 500,
                                                color: "#00B3E2",
                                                fontSize: "12px",
                                                textAlign: "center",
                                            }}
                                        >
                                            POINTS
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            )}
        </>
    );
};

export default LobbyLeaderboard;
