import { Typography, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DoneIcon from "@mui/icons-material/Done";

const TfAnswers = ({
    questionObj,
    buttonBackgroundColor,
    correctAnswer,
    selectedAns,
}) => {
    const theme = useTheme();

    return (
        <>
            <Button
                name={questionObj.id}
                sx={{
                    backgroundColor:
                        correctAnswer === "true"
                            ? theme.palette.correct.main
                            : buttonBackgroundColor,
                    color: `${theme.palette.textColor.main} !important`,
                    height: "50px",
                    borderRadius: theme.borderRadius.secondary,
                    "&:hover": {
                        backgroundColor:
                            correctAnswer === "true"
                                ? theme.palette.correct.main
                                : buttonBackgroundColor,
                        color: theme.palette.textColor.main,
                    },
                    opacity: "1",
                    filter:
                        selectedAns === true
                            ? "brightness(1.2)"
                            : "brightness(1)",
                }}
                disabled={true}
            >
                <Typography
                    variant="body1"
                    sx={{
                        fontWeight: "600",
                        textTransform: "capitalize",
                        fontSize: theme.font.answer,
                    }}
                >
                    <span
                        style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                        }}
                    >
                        True{" "}
                    </span>
                    {correctAnswer === "true" ? (
                        <DoneIcon
                            sx={{
                                verticalAlign: "bottom",
                                paddingLeft: "5px",
                                paddingBottom: "5px",
                                fontWeight: "700",
                            }}
                        />
                    ) : (
                        ""
                    )}
                </Typography>
            </Button>
            <Button
                name={questionObj.id}
                sx={{
                    backgroundColor:
                        correctAnswer === "false"
                            ? theme.palette.correct.main
                            : buttonBackgroundColor,
                    color: `${theme.palette.textColor.main} !important`,
                    height: "50px",
                    borderRadius: theme.borderRadius.secondary,
                    "&:hover": {
                        backgroundColor:
                            correctAnswer === "false"
                                ? theme.palette.correct.main
                                : buttonBackgroundColor,
                        color: theme.palette.textColor.main,
                    },
                    opacity: "1",
                    filter:
                        selectedAns === false
                            ? "brightness(1.2)"
                            : "brightness(1)",
                }}
                disabled={true}
            >
                <Typography
                    variant="body1"
                    sx={{
                        fontWeight: "600",
                        textTransform: "capitalize",
                        fontSize: theme.font.answer,
                    }}
                >
                    <span
                        style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                        }}
                    >
                        False{" "}
                    </span>
                    {correctAnswer === "false" ? (
                        <DoneIcon
                            sx={{
                                verticalAlign: "middle",
                                paddingLeft: "5px",
                                paddingBottom: "5px",
                                fontWeight: "700",
                            }}
                        />
                    ) : (
                        ""
                    )}
                </Typography>
            </Button>
        </>
    );
};

export default TfAnswers;
