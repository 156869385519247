/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import {
    Avatar,
    Badge,
    Button,
    ListItem,
    Tooltip,
    Typography,
    Box,
} from "@mui/material";

import { AVATAR_SIZE, GAME_STATUS } from "../../helpers/constant";

import playerCSS from "./Player.module.css";

// import icons
import CheckIcon from "@mui/icons-material/Check";

import { faCrown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatScore } from "../../helpers/Global";

export default function Player({ player }) {
    const gameStatus = useSelector(({ game }) => game.gameStatus);

    let [showTooltip, setShowTooltip] = useState(false);

    function isLobbyRoom() {
        return (
            gameStatus === GAME_STATUS.WAITING ||
            gameStatus === GAME_STATUS.COUNTDOWN
        );
    }

    function hasCrown() {
        return player.isLeader && !isLobbyRoom();
    }

    function isAnswerCorrect() {
        return player.isCorrect && !isLobbyRoom();
    }

    const active =
        gameStatus === GAME_STATUS.WAITING
            ? playerCSS.active
            : player.didAnswer || player.correct
            ? playerCSS.active
            : playerCSS.inactive;

    function handleShowTooltip() {
        setShowTooltip(true);
    }

    function handlehideTooltip() {
        setShowTooltip(false);
    }

    function lobbyPlayer() {
        return [
            <>
                <Typography
                    noWrap
                    component="h6"
                    sx={{
                        fontSize: { xs: "0.7rem", sm: "1rem" },
                        textAlign: { xs: "center" },
                        opacity: player.name === "open" && 0.6,
                    }}
                >
                    {player.name}
                </Typography>
                <Badge
                    overlap="circular"
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    sx={{
                        width: "auto",
                        mt: { md: hasCrown() ? 2 : 0 },
                        position: "relative",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Button
                            variant="text"
                            onClick={handleShowTooltip}
                            onMouseEnter={handleShowTooltip}
                            onMouseLeave={handlehideTooltip}
                            sx={{
                                padding: "0px",
                                backgroundColor: "transparent!important",
                                minWidth: "auto",
                            }}
                        >
                            <Tooltip
                                onClose={handlehideTooltip}
                                open={showTooltip}
                                disableFocusListener
                                title={player.name}
                            >
                                <Avatar
                                    src={player.avatar}
                                    sx={{
                                        mx: "auto",
                                        padding: "5%",
                                        border:
                                            player.name === "open"
                                                ? {
                                                      xs: "2px dashed #fff",
                                                      sm: "2px dashed #fff",
                                                  }
                                                : { xs: 2, md: 4 },
                                        borderColor: "transparent!important",
                                        opacity:
                                            player.name === "open" ||
                                            isAnswerCorrect()
                                                ? "0.6"
                                                : "1",
                                        width: AVATAR_SIZE.other.md,
                                        height: AVATAR_SIZE.other.md,
                                        backgroundColor: player.bgColor,
                                        "> img": {
                                            borderRadius: "50%",
                                        },
                                    }}
                                >
                                    {" "}
                                </Avatar>
                            </Tooltip>
                        </Button>
                    </Box>

                    <FontAwesomeIcon
                        icon={faCrown}
                        className={
                            hasCrown() ? playerCSS.crown : playerCSS.noCrown
                        }
                    />

                    <CheckIcon
                        className={playerCSS.playerCorrect}
                        sx={{
                            display: isAnswerCorrect() ? "block" : "none",
                            fontSize: {
                                xs: "30px",
                                sm: "50px",
                            },
                            fontWeight: "600",
                        }}
                    />

                    <Typography
                        className={playerCSS.playerScore}
                        noWrap
                        variant="body1"
                        sx={{
                            textAlign: { xs: "center" },
                            letterSpacing: "-0.3px",
                            lineHeight: "1.2",
                        }}
                    >
                        {player.score ? formatScore(player.score) : 0}
                    </Typography>
                </Badge>
            </>,
        ];
    }

    function ingamePlayer() {
        return [
            <>
                <Badge
                    overlap="circular"
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    sx={{
                        width: "auto",
                        mt: { md: hasCrown() ? 2 : 0 },
                        position: "relative",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Button
                            variant="text"
                            onClick={handleShowTooltip}
                            onMouseEnter={handleShowTooltip}
                            onMouseLeave={handlehideTooltip}
                            sx={{
                                padding: "0px",
                                backgroundColor: "transparent!important",
                                minWidth: "auto",
                            }}
                        >
                            <Tooltip
                                onClose={handlehideTooltip}
                                open={showTooltip}
                                disableFocusListener
                                title={player.name}
                            >
                                <Avatar
                                    src={player.avatar}
                                    sx={{
                                        mx: "auto",
                                        padding: "5%",
                                        border:
                                            player.name === "open"
                                                ? {
                                                      xs: "2px dashed #fff",
                                                      sm: "2px dashed #fff",
                                                  }
                                                : { xs: 2, md: 4 },
                                        borderColor: "transparent!important",
                                        opacity:
                                            player.name === "open" ||
                                            isAnswerCorrect()
                                                ? "0.6"
                                                : "1",
                                        width: AVATAR_SIZE.other.md,
                                        height: AVATAR_SIZE.other.md,
                                        backgroundColor: player.bgColor,
                                        "> img": {
                                            borderRadius: "50%",
                                        },
                                    }}
                                >
                                    {" "}
                                </Avatar>
                            </Tooltip>
                        </Button>
                    </Box>

                    <FontAwesomeIcon
                        icon={faCrown}
                        className={
                            hasCrown() ? playerCSS.crown : playerCSS.noCrown
                        }
                    />

                    <CheckIcon
                        className={playerCSS.playerCorrect}
                        sx={{
                            display: isAnswerCorrect() ? "block" : "none",
                            fontSize: {
                                xs: "30px",
                                sm: "50px",
                            },
                            fontWeight: "600",
                        }}
                    />
                </Badge>
                <Typography
                    noWrap
                    component="h6"
                    sx={{
                        fontSize: { xs: "0.7rem", sm: "1rem" },
                        textAlign: { xs: "center" },
                        opacity: player.name === "open" && 0.6,
                    }}
                >
                    {player.name}
                </Typography>

                <Typography
                    noWrap
                    variant="body1"
                    sx={{
                        textAlign: { xs: "center" },
                    }}
                >
                    {player.score ? formatScore(player.score) : 0}
                </Typography>
            </>,
        ];
    }

    return (
        <ListItem
            className={`${active}`}
            sx={{
                width: "100%",
                alignItems: "start",
                padding: "8px !important",
            }}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ width: "100%", flexDirection: "column" }}
            >
                {isLobbyRoom() ? lobbyPlayer() : ingamePlayer()}
            </Box>
        </ListItem>
    );
}
