import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Typography, Paper } from "@mui/material";

const MessageWaitingCountdown = () => {
    const messageWaitingCountdown = useSelector(
        ({ message }) => message.messageWaitingCountdown
    );

    const subCategory = useSelector(({ game }) => game.subCategory);

    return (
        <Box
            sx={{
                display: "flex",
                height: "100vh",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Paper
                sx={{
                    padding: "2rem",
                    backgroundColor: "black",
                    color: "#fff",
                    border: "3px solid #00b3e2",
                }}
            >
                <Typography
                    variant="h3"
                    align="center"
                    sx={{ textTransform: "uppercase" }}
                >
                    Today’s Trivia Topic: {subCategory}
                </Typography>
                <Typography variant="h5" align="center">
                    in {messageWaitingCountdown}
                </Typography>
            </Paper>
        </Box>
    );
};

export default MessageWaitingCountdown;
