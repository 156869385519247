import React, { useRef } from "react";
import { Box } from "@mui/material";
import Settings from "../Settings/Settings";

const LobbySettings = () => {
    const settingsContainer = useRef();

    return (
        <>
            <Box
                sx={{ display: "flex", width: "100%", flexDirection: "column" }}
            >
                <Box
                    ref={settingsContainer}
                    sx={{
                        mb: { xs: 0, md: "1vh" },
                        width: "100%",
                        height: { xs: "auto" },
                        overflow: "hidden",
                        paddingTop: 0,
                    }}
                >
                    <Settings />
                </Box>
            </Box>
        </>
    );
};

export default LobbySettings;
