const initialState = {
    message: "",
    error: "",
    messageWaitingCountdown: "",
    openSnackbar: false,
    openCountDownModal: false,
};

const MessageReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_MESSAGE":
            return Object.assign({}, state, {
                message: action.data.message,
                openSnackbar: action.data.openSnackbar,
            });

        case "ADD_ERROR":
            return Object.assign({}, state, {
                error: action.data,
            });

        case "ADD_MESSAGE_WAITING_COUNTDOWN":
            return Object.assign({}, state, {
                messageWaitingCountdown: action.data,
            });

        case "OPEN_COUNTDOWN_MODAL":
            return Object.assign({}, state, {
                openCountDownModal: action.data,
            });

        default:
            return state;
    }
};

//HANDLE Message
export const addMessage = (msg, openSnackbar) => {
    return {
        type: "ADD_MESSAGE",
        data: { message: msg, openSnackbar: openSnackbar },
    };
};

// HANDLE ERROR
export const addError = (error) => {
    return {
        type: "ADD_ERROR",
        data: error,
    };
};

// HANDLE WATING COUNTDOWN
export const addMesageWaitingCountdown = (message) => {
    return {
        type: "ADD_MESSAGE_WAITING_COUNTDOWN",
        data: message,
    };
};

// HANDLE OPEN COUNTDOWN MODAL
export const openCountDownModal = (openCountDownModal) => {
    return {
        type: "OPEN_COUNTDOWN_MODAL",
        data: openCountDownModal,
    };
};

export default MessageReducer;
