/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Lobby from "./components/Lobby/Lobby";
import Play from "./components/Play/Play";
import useSocketEvents from "./hooks/useSocketEvents";
import { useNavigate } from "react-router-dom";
import {
    SOCKET_ACTIONS,
    SOCKET_RECEIVE_EVENTS,
    GAME_STATUS,
} from "./helpers/constant";
import {
    populatePlayers,
    updateGameStatus,
    updateSubCategory,
    updateBGMusic,
} from "./reducers/GameReducer";

import { addError, addMesageWaitingCountdown } from "./reducers/MessageReducer";
import { addTimer } from "./reducers/TimerReducer";
import {
    updateQuestions,
    updateCurrentVideo,
    updateNextVideo,
    updateQuestion,
} from "./reducers/QuestionsReducer";
import { useSelector, useDispatch } from "react-redux";
import EndScreen from "./components/EndScreen/EndScreen";

const GameRoutes = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const bgMusic = useSelector(({ game }) => game.bgMusic);
    const [bgMusicIndex, setBGMusicIndex] = useState(-1);
    var [bgm, setBgm] = useState(new Audio());

    useEffect(() => {
        if (!bgMusic[bgMusicIndex]) return;

        if (bgm.src === "") {
            bgm.addEventListener(
                "ended",
                function () {
                    this.pause();

                    setBGMusicIndex((prevIndex) =>
                        prevIndex + 1 === bgMusic.length ? 0 : prevIndex + 1
                    );
                },
                false
            );
        }

        bgm.pause();
        bgm.src = bgMusic[bgMusicIndex];
        bgm.volume = 0.1;

        setTimeout(() => {
            bgm.play();
        }, 3000);
    }, [bgMusicIndex]);

    // receive current game details and update reducers accordingly
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.GAME_DETAILS,
        null,
        (room) => {
            console.log(SOCKET_RECEIVE_EVENTS.GAME_DETAILS, room);
            dispatch(updateBGMusic(room.bgMusic ? room.bgMusic : []));
            setBGMusicIndex(0);
            dispatch(
                updateSubCategory(room.subCategory ? room.subCategory : "")
            );

            //update gameStatus
            dispatch(updateGameStatus(room.status ? room.status : ""));
            //update players
            dispatch(populatePlayers(room.players ? room.players : []));

            //update questions and reset question index
            dispatch(updateQuestions(room.questions));
            dispatch(updateQuestion(0));

            //update currentVideo and nextVideo
            dispatch(updateCurrentVideo(room.currentVideo));
            dispatch(updateNextVideo(room.nextVideo));
        }
    );

    // receive update status
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.UPDATE_STATUS,
        null,
        (status) => {
            // console.log(`${SOCKET_RECEIVE_EVENTS.UPDATE_STATUS} ${status}`);
            dispatch(addMesageWaitingCountdown(""));
            dispatch(updateGameStatus(status));

            switch (status) {
                case GAME_STATUS.WAITING:
                case GAME_STATUS.COUNTDOWN:
                    navigate("/");
                    break;

                case GAME_STATUS.QUESTIONS:
                    navigate("/play");
                    break;

                case GAME_STATUS.END:
                    navigate("/end");
                    break;
            }
        }
    );

    // receive video data update (current and next video)
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.VIDEO_DATA,
        null,
        (currentVideo, questions, nextVideo) => {
            // console.log(
            //     SOCKET_RECEIVE_EVENTS.VIDEO_DATA,
            //     currentVideo,
            //     nextVideo,
            //     questions
            // );
            //update currentVideo and nextVideo
            dispatch(updateCurrentVideo(currentVideo));
            dispatch(updateNextVideo(nextVideo));

            //update questions and questionIndex
            dispatch(updateQuestions(questions));
            dispatch(updateQuestion(0));
        }
    );

    // receive starting game timer
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.WAITING_COUNTDOWN,
        null,
        (countdown) => {
            dispatch(addMesageWaitingCountdown(countdown));
        }
    );

    // receive starting game timer
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.START_COUNTDOWN,
        null,
        (timer) => {
            dispatch(addTimer(timer));
        }
    );

    //callback when token authentication fails
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.CONNECT_ERROR,
        null,
        (err) => {
            //on connection refused, do something...
            console.log(err.message); // prints the message associated with the error
        }
    );

    // when game ends
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.GAME_ENDS,
        null,
        (data) => {
            dispatch(populatePlayers(data));
        }
    );

    // receive game already started error
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.HANDLE_ERROR,
        null,
        (errorMessage) => {
            dispatch(addError(errorMessage));
        }
    );

    return (
        <>
            <Routes>
                <Route exact path="/" element={<Lobby />} />
                <Route exact path="/play" element={<Play />} />
                <Route exact path="/end" element={<EndScreen />} />
            </Routes>
        </>
    );
};

export default GameRoutes;
