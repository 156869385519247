import React from "react";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import playerCSS from "./PlayerLobbyView.module.css";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function PlayerLobbyView({ questionObj, currentVideo }) {
    const triviaImage =
        currentVideo && currentVideo.image ? currentVideo.image : "";
    const matches = useMediaQuery("(min-width:750px)");

    return (
        <>
            {triviaImage !== null && (
                <Box
                    sx={{
                        height: "auto",
                        px: 0,
                        py: 0,
                        position: "relative",
                    }}
                >
                    <Box
                        sx={{
                            height: "auto",
                            px: 0,
                            py: 0,
                            position: "relative",
                        }}
                    >
                        <img
                            src={`${triviaImage}`}
                            srcSet={`${triviaImage}`}
                            alt={questionObj ? questionObj.questionObj : ""}
                            loading="lazy"
                            style={{
                                width: "100%",
                                borderRadius: "8px",
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            height: "100%",
                            width: "100%",
                            position: "absolute",
                            top: 0,
                            backgroundImage: `linear-gradient(180deg, #181818 2%, rgba(24, 24, 24, 0.2) 40%)`,
                        }}
                    ></Box>
                </Box>
            )}
        </>
    );
}
