import React from "react";
import { Box, Typography } from "@mui/material";

import { useSelector } from "react-redux";
import PlayerLobbyView from "../PlayerLobbyView/PlayerLobbyView";

const Settings = () => {
    const questionObj = useSelector(({ question }) => question.questionObject);
    const currentVideo = useSelector(({ question }) => question.currentVideo);

    return (
        <Box
            sx={{
                height: `100%`,
            }}
        >
            <PlayerLobbyView
                questionObj={questionObj}
                currentVideo={currentVideo}
            />
            <Box
                sx={{
                    height: "100%",
                    width: "100%",
                }}
            >
                <Typography
                    variant="body1"
                    sx={{
                        textTransform: "uppercase",
                        fontWeight: 500,
                        fontSize: 16,
                        color: "rgba(238, 241, 248, 0.7)",
                    }}
                >
                    Trivia Related To:
                </Typography>

                <Typography
                    variant="body1"
                    sx={{
                        color: "rgba(255, 255, 255, 0.7)",
                        fontWeight: 600,
                        fontSize: 26,
                        textTransform: "capitalize",
                    }}
                >
                    {currentVideo ? currentVideo.title : ""}
                </Typography>
            </Box>
        </Box>
    );
};

export default Settings;
