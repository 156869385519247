const initialState = {
    index: 0,
    questionObject: undefined,
    questions: [],
    questionsCount: 0,
    maxSeconds: null,
    currentVideo: null,
    nextVideo: null,
};

const QuestionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_QUESTIONS":
            return Object.assign({}, state, {
                questions: action.data,
                questionsCount: action.data ? action.data.length : 0,
            });

        case "UPDATE_QUESTION":
            return Object.assign({}, state, {
                index: action.data,
                questionObject: state.questions
                    ? state.questions[action.data]
                    : undefined,
            });

        case "UPDATE_MAX_SECONDS":
            return Object.assign({}, state, {
                maxSeconds: action.data,
            });

        case "UPDATE_CURRENT_VIDEO":
            return Object.assign({}, state, {
                currentVideo: action.data,
            });

        case "UPDATE_NEXT_VIDEO":
            return Object.assign({}, state, {
                nextVideo: action.data,
            });

        default:
            return state;
    }
};

//update questions
export const updateQuestions = (questions) => {
    return {
        type: "UPDATE_QUESTIONS",
        data: questions,
    };
};

//update current question
export const updateQuestion = (index) => {
    return {
        type: "UPDATE_QUESTION",
        data: index,
    };
};

//update maxSeconds
export const updateMaxSeconds = (seconds) => {
    return {
        type: "UPDATE_MAX_SECONDS",
        data: seconds,
    };
};

//update current video
export const updateCurrentVideo = (currentVideo) => {
    return {
        type: "UPDATE_CURRENT_VIDEO",
        data: currentVideo,
    };
};

//update next video
export const updateNextVideo = (nextVideo) => {
    return {
        type: "UPDATE_NEXT_VIDEO",
        data: nextVideo,
    };
};

export default QuestionsReducer;
