import React from "react";
import { CssBaseline, Button, Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const Timer = ({ preLabel, maxSeconds }) => {
    const timer = useSelector(({ timer }) => timer.timer);

    const widthPerSeconds = (100 / maxSeconds).toFixed(2);
    const widthMultiple = maxSeconds - timer;

    return (
        <>
            <CssBaseline />
            <Box
                sx={{
                    marginTop: "16px",
                }}
            >
                <Box m="auto" sx={{ width: "75%" }}>
                    <Box
                        textAlign="center"
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingTop: { xs: "8px", md: "5px" },
                        }}
                    >
                        <Box sx={{ display: "inline-flex" }}>
                            <Typography
                                variant="body1"
                                sx={{
                                    textAlign: "center",
                                    fontWeight: 500,
                                    fontSize: 24,
                                    color: "rgba(238, 241, 248, 0.7)",
                                    display: "inline-flex",
                                }}
                            >
                                {preLabel}
                            </Typography>
                            <div>&nbsp;</div>
                            <Box
                                sx={{
                                    backgroundColor:
                                        "rgba(255, 255, 255, 0.05)",
                                    padding: "0px 16px",
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        textAlign: "center",
                                        fontWeight: 700,
                                        fontSize: 30,
                                        color: "#FFFFFF",
                                    }}
                                >
                                    {timer > 0 ? timer : "0"}
                                </Typography>
                            </Box>
                            <div>&nbsp;</div>
                            <Typography
                                variant="body1"
                                sx={{
                                    textAlign: "center",
                                    fontWeight: 500,
                                    fontSize: 24,
                                    color: "rgba(238, 241, 248, 0.7)",
                                    display: "inline-flex",
                                }}
                            >
                                seconds
                            </Typography>
                        </Box>
                    </Box>
                    <Button
                        sx={{
                            marginTop: "8px",
                            width: "100%",
                            backgroundColor: "#fff",
                            height: "8px",
                            borderRadius: "0",
                            display: "flex",
                            justifyContent: "initial",
                            padding: "0",
                        }}
                        disabled={true}
                    >
                        <Box
                            style={{
                                backgroundColor: "#5797c3",
                                width: `${widthMultiple * widthPerSeconds}%`,
                                height: "100%",
                            }}
                        ></Box>
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default Timer;
