import React from "react";
import { Box, Typography } from "@mui/material";

import topicTitleStroke from "./topic_title_stroke.png";
import triangleTL from "./triangles_tl.png";
import triangleBR from "./triangles_br.png";

const LobbyTopicTitle = ({ subCategory }) => {
    return (
        <Box
            sx={{
                paddingTop: "30px",
                paddingBottom: "16px",
            }}
        >
            <Box display="flex">
                <Box m="auto" sx={{ position: "relative" }}>
                    <img
                        src={topicTitleStroke}
                        style={{
                            width: "100%",
                            position: "absolute",
                            height: "100%",
                            left: "-10px",
                            top: "-10px",
                        }}
                    />

                    <img
                        src={triangleTL}
                        style={{
                            position: "absolute",
                            height: "100px",
                            left: "-40px",
                            top: "-30px",
                        }}
                    />

                    <img
                        src={triangleBR}
                        style={{
                            position: "absolute",
                            height: "100px",
                            right: "-70px",
                            bottom: "-40px",
                        }}
                    />

                    <Typography
                        noWrap
                        variant="body1"
                        sx={{
                            textTransform: "uppercase",
                            fontWeight: 700,
                            fontSize: 40,
                            backgroundColor: "#222222",
                            padding: "0px 30px",
                            position: "relative",
                        }}
                    >
                        Today’s Trivia Topic:
                    </Typography>
                </Box>
            </Box>

            <Box display="flex">
                <Box
                    m="auto"
                    sx={{
                        backgroundColor: "#3DE8FF",
                        paddingTop: "2px",
                        paddingBottom: "2px",
                        paddingLeft: "16px",
                        paddingRight: "16px",
                        minWidth: "100px",
                        borderRadius: "0px 0px 4px 4px",
                    }}
                >
                    <Typography
                        noWrap
                        variant="body1"
                        sx={{
                            textTransform: "uppercase",
                            textAlign: "center",
                            color: "rgba(43, 43, 43, 1)",
                            fontWeight: 700,
                            fontSize: 24,
                        }}
                    >
                        {subCategory ? subCategory : ""}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default LobbyTopicTitle;
