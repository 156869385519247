import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { List, Stack } from "@mui/material";
import Player from "../Player/Player";
import useSocketEvents from "../../hooks/useSocketEvents";
import { playerAnswered } from "../../reducers/GameReducer";
import { SOCKET_ACTIONS, SOCKET_RECEIVE_EVENTS } from "../../helpers/constant";

const Players = ({ direction = "row", alignItems = "flex-end" }) => {
    const dispatch = useDispatch();

    const players = useSelector(({ game }) => {
        return game.gamePlayers;
    });

    const gameStatus = useSelector(({ game }) => {
        return game.gameStatus;
    });

    const handlePlayerAnswered = useCallback(
        (playerId) => {
            dispatch(playerAnswered(playerId));
        },
        [dispatch]
    );

    //add PLAYER_ANSWERED event,
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.PLAYER_ANSWERED,
        null,
        handlePlayerAnswered
    );

    return (
        <>
            <List
                sx={{
                    px: {
                        xs: "2px",
                        sm: 0,
                    },
                    py: 0,
                    m: 0,
                }}
                component={Stack}
                direction={{ xs: direction }}
                alignItems={alignItems}
            >
                {players !== undefined &&
                    players.map((player) => {
                        return <Player key={Math.random()} player={player} />;
                    })}
                {gameStatus === "waiting" && <OpenSpot />}
            </List>
        </>
    );
};

export default Players;

const OpenSpot = () => {
    const players = useSelector(({ game }) => {
        return game.gamePlayers;
    });

    let freeSpots = [
        { id: null, name: "open" },
        { id: null, name: "open" },
        { id: null, name: "open" },
        { id: null, name: "open" },
        { id: null, name: "open" },
        { id: null, name: "open" },
    ];

    return (
        <>
            {freeSpots.map((spot, index) => {
                return (
                    index + players.length < 5 && (
                        <Player key={index} player={spot} />
                    )
                );
            })}
        </>
    );
};
