import { Typography, CssBaseline, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DoneIcon from "@mui/icons-material/Done";

const MultipleAnswers = ({
    questionObj,
    buttonBackgroundColor,
    correctAnswer,
    matches,
    selectedAns,
}) => {
    const theme = useTheme();

    return (
        <>
            <CssBaseline />
            {questionObj.answers.map((answer, index) => (
                <Button
                    name={answer.id}
                    key={answer.id}
                    sx={{
                        backgroundColor:
                            parseInt(correctAnswer) === parseInt(answer.id)
                                ? theme.palette.correct.main
                                : buttonBackgroundColor,
                        color: `${theme.palette.textColor.main} !important`,
                        height: matches ? "50px" : "40px",
                        borderRadius: theme.borderRadius.secondary,
                        "&:hover": {
                            backgroundColor:
                                parseInt(correctAnswer) === parseInt(answer.id)
                                    ? theme.palette.correct.main
                                    : buttonBackgroundColor,
                            color: `${theme.palette.textColor.main} !important`,
                        },
                        opacity: "1",
                        filter:
                            selectedAns === answer.id
                                ? "brightness(1.2)"
                                : "brightness(1)",
                    }}
                    disabled={true}
                >
                    <Typography
                        variant="body1"
                        sx={{
                            fontWeight: "600",
                            textTransform: "capitalize",
                            lineHeight: "1",
                            fontSize: theme.font.answer,
                        }}
                    >
                        <span
                            style={{
                                display: "inline-block",
                                verticalAlign: "middle",
                            }}
                        >
                            {answer.answer}{" "}
                        </span>
                        {parseInt(correctAnswer) === answer.id ? (
                            <DoneIcon
                                sx={{
                                    fontWeight: "700",
                                    lineHeight: "normal",
                                    verticalAlign: "middle",
                                    paddingLeft: "5px",
                                    paddingBottom: "5px",
                                }}
                            />
                        ) : (
                            ""
                        )}
                    </Typography>
                </Button>
            ))}
        </>
    );
};

export default MultipleAnswers;
