/* eslint-disable no-unused-vars */
import React from "react";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import styles from "./NextVideo.module.css";
import { useTheme } from "@mui/material/styles";

const NextVideo = () => {
    const theme = useTheme();
    const nextVideo = useSelector(({ question }) => question.nextVideo);

    return (
        <Box
            sx={{
                height: "100%",
                px: 0,
                py: 0,
                position: "relative",
            }}
        >
            <Box
                className={styles.nextVideo}
                sx={{
                    backgroundImage: `linear-gradient(180deg, #181818 2%, rgba(24, 24, 24, 0.5) 40%), url(${
                        nextVideo && nextVideo.thumbnail
                            ? nextVideo.thumbnail
                            : ""
                    })`,
                    borderRadius: `${theme.borderRadius.primary}`,
                }}
            >
                <Typography
                    variant="body1"
                    sx={{
                        color: "#FFFFFF",
                        fontWeight: "bold",
                        fontSize: 14,
                        fontWeight: 600,
                        textTransform: "uppercase",
                        padding: "8px 16px",
                    }}
                >
                    Next questions will be about:
                </Typography>

                <Typography
                    variant="body1"
                    sx={{
                        color: "#FFFFFF",
                        fontWeight: "bold",
                        fontSize: 24,
                        fontWeight: 600,
                        textTransform: "uppercase",
                        padding: "0px 16px",
                    }}
                >
                    {nextVideo ? nextVideo.title : ""}
                </Typography>
            </Box>
        </Box>
    );
};

export default NextVideo;
