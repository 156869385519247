/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from "react";
import { Box, Container, CssBaseline, Grid, Typography } from "@mui/material";

import Players from "../Players/Players";
import LobbySettings from "../LobbySettings/LobbySettings";
import styles from "./Lobby.module.css";
import { useLocation } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import useSocket from "../../hooks/useSocket";
import { addToken } from "../../reducers/TokenReducer";

import { useSelector, useDispatch } from "react-redux";

import { GAME_STATUS } from "../../helpers/constant";
import SnackBar from "../../helpers/SnackBar";
import ErrorPage from "../ErrorPage/ErrorPage";
import MessageWaitingCountdown from "../MessageWaitingCountdown/MessageWaitingCountdown";
import { useNavigate } from "react-router-dom";

import LobbyLeaderboard from "../LobbyLeaderboard/LobbyLeaderboard";
import Timer from "../Timer/Timer";
import LobbyTopicTitle from "../LobbyTopicTitle/LobbyTopicTitle";

const Lobby = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const playersBox = useRef();

    const search = useLocation().search;
    let token = useSelector(({ token }) => token.token);

    const message = useSelector(({ message }) => message.message);
    const openSnackbar = useSelector(({ message }) => message.openSnackbar);
    const error = useSelector(({ message }) => message.error);
    const waitingCountdown = useSelector(
        ({ message }) => message.messageWaitingCountdown
    );
    const gameStatus = useSelector(({ game }) => game.gameStatus);
    const subCategory = useSelector(({ game }) => game.subCategory);

    // get all the player details when lobby page is opened
    useEffect(() => {
        const searchParams = new URLSearchParams(search);

        const token = searchParams.get("token");
        const loggedIn = searchParams.get("loggedIn");
        if (token !== null) {
            dispatch(addToken(token, loggedIn));
        }
    }, [dispatch, search]);

    //establish socket connection
    useSocket(token);

    useEffect(() => {
        // console.log("STATUS IS", gameStatus, process.env.REACT_APP_SOCKET_URL);

        if (gameStatus === GAME_STATUS.END) {
            navigate("/end");
        }

        if (gameStatus === GAME_STATUS.QUESTIONS) {
            navigate("/play");
        }
    }, [dispatch, gameStatus, navigate]);

    return (
        /* if no error, lobby page, else errorPage */
        error === "" && waitingCountdown === "" ? (
            <>
                <CssBaseline />
                <Container
                    className={styles.backgroundColor}
                    sx={{
                        padding: "0",
                        width: "100% !important",
                        maxWidth: "100% !important",
                        paddingLeft: {
                            lg: "10%",
                            xl: theme.mainContainer.paddingLeft,
                        },
                        paddingRight: {
                            lg: "10%",
                            xl: theme.mainContainer.paddingRight,
                        },
                        height: theme.mainContainer.height,
                    }}
                >
                    <Grid
                        container
                        sx={{
                            height: "100%",
                        }}
                        spacing="0"
                    >
                        <Grid item xs={2}>
                            <Box
                                sx={{
                                    height: "100%",
                                    width: { xs: "100%" },
                                    overflow: "hidden",
                                    paddingTop: theme.size.distanceFromTop,
                                    paddingBottom: theme.px10.main,
                                }}
                                ref={playersBox}
                            >
                                <Players
                                    direction="column"
                                    alignItems="flex-start"
                                />
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={7}
                            sx={{ paddingLeft: "16px", paddingBottom: "16px" }}
                        >
                            <LobbyTopicTitle subCategory={subCategory} />
                            <LobbySettings />

                            <Timer
                                preLabel="Next question in:"
                                maxSeconds="5"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <LobbyLeaderboard />
                        </Grid>
                    </Grid>
                    {message !== "" && (
                        <SnackBar open={openSnackbar} message={message} />
                    )}
                </Container>
            </>
        ) : error !== "" ? (
            <ErrorPage />
        ) : (
            <MessageWaitingCountdown />
        )
    );
};

export default Lobby;
