const initialState = {
    gamePlayers: [], //list of current players
    gameStatus: null,
    subCategory: null,
    bgMusic: [],
};

const GameReducer = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_GAME_STATUS":
            return Object.assign({}, state, {
                gameStatus: action.data,
            });

        case "POPULATE_PLAYERS":
            return Object.assign({}, state, {
                gamePlayers: action.data,
            });

        case "PLAYER_RESET": {
            const copy = [...state.gamePlayers].map((player) => {
                action.data.forEach((field) => {
                    player[field] = false;
                });
                return player;
            });

            return Object.assign({}, state, {
                gamePlayers: copy,
            });
        }

        case "PLAYER_ANSWERED": {
            const copy = [...state.gamePlayers];
            const player = copy.find((p) => p.id == action.data);

            if (player) player.didAnswer = true;

            return Object.assign({}, state, {
                gamePlayers: copy,
            });
        }

        case "UPDATE_TRIVIA_TOPIC":
            return Object.assign({}, state, {
                subCategory: action.data,
            });

        case "UPDATE_BG_MUSIC":
            return Object.assign({}, state, {
                bgMusic: action.data,
            });

        default:
            return state;
    }
};

//adding game status
export const updateGameStatus = (gameStatus) => {
    return {
        type: "UPDATE_GAME_STATUS",
        data: gameStatus,
    };
};

//populate players
export const populatePlayers = (players) => {
    return {
        type: "POPULATE_PLAYERS",
        data: players,
    };
};

//player reset, method to reset player's didAnswer, isCorrect, and isLeader
export const playerReset = (
    playerFields = ["didAnswer", "isCorrect", "isLeader"]
) => {
    return {
        type: "PLAYER_RESET",
        data: playerFields,
    };
};

//player answered
export const playerAnswered = (playerId) => {
    return {
        type: "PLAYER_ANSWERED",
        data: playerId,
    };
};

//update trivia topic
export const updateSubCategory = (subCategory) => {
    return {
        type: "UPDATE_TRIVIA_TOPIC",
        data: subCategory,
    };
};

//update bgMusic
export const updateBGMusic = (bgMusic) => {
    return {
        type: "UPDATE_BG_MUSIC",
        data: bgMusic,
    };
};

export default GameReducer;
