const initialState = {
    timer: null,
};

const TimerReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_TIMER":
            return Object.assign({}, state, {
                timer: action.data,
            });

        default:
            return state;
    }
};

// HANDLE STARTING TIMER
export const addTimer = (timer) => {
    return {
        type: "ADD_TIMER",
        data: timer,
    };
};

export default TimerReducer;
