import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import GameReducer from "./GameReducer";
import SocketReducer from "./SocketReducer";
import TokenReducer from "./TokenReducer";
import SettingsReducer from "./SettingsReducer";
import MessageReducer from "./MessageReducer";
import TimerReducer from "./TimerReducer";
import QuestionsReducer from "./QuestionsReducer";
import AnswersReducer from "./AnswersReducer";
import { composeWithDevTools } from "redux-devtools-extension";

const reducer = combineReducers({
    game: GameReducer,
    socket: SocketReducer,
    token: TokenReducer,
    settings: SettingsReducer,
    message: MessageReducer,
    timer: TimerReducer,
    question: QuestionsReducer,
    answers: AnswersReducer,
});

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;
