import React from "react";

import { Avatar, Badge, Typography, Box, Grid } from "@mui/material";
import { AVATAR_SIZE } from "../../helpers/constant";
import playerCSS from "../Player/Player.module.css";
import { faCrown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function PlayerEndSreen({ index, player }) {
    return (
        <>
            <Grid
                container
                item
                alignItems="center"
                columnSpacing={2}
                sx={{ py: 1 }}
            >
                <Grid item align="center" xs={3} sm={4}>
                    <Typography noWrap variant="body1">
                        {index + 1}
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={5}>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="left"
                    >
                        <Badge
                            overlap="circular"
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            sx={{
                                width: "auto",
                                mt: player.isLeader ? 2 : 0,
                            }}
                        >
                            <Avatar
                                src={player.avatar}
                                sx={{
                                    width: {
                                        xs: AVATAR_SIZE.other.xs,
                                        sm: AVATAR_SIZE.other.sm,
                                    },
                                    height: {
                                        xs: AVATAR_SIZE.other.xs,
                                        sm: AVATAR_SIZE.other.sm,
                                    },
                                }}
                            >
                                {" "}
                            </Avatar>

                            <FontAwesomeIcon
                                icon={faCrown}
                                className={
                                    player.isLeader
                                        ? playerCSS.crown
                                        : playerCSS.noCrown
                                }
                            />
                        </Badge>
                        <Typography
                            noWrap
                            variant="body1"
                            sx={{
                                ml: 2,
                                maxWidth: { xs: "120px", md: "150px" },
                            }}
                        >
                            {player.name}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={3} sm={3}>
                    {player.score} point{player.score <= 1 ? "" : "s"}
                </Grid>
            </Grid>
        </>
    );
}
