export const AVATAR_SIZE = {
    me: { xs: 60, sm: 85 },
    other: { xs: 48, sm: 48, md: 64 },
};

//constant pass when calling an socket emit or receive
export const SOCKET_ACTIONS = {
    SEND: true,
    RECEIVE: false,
};

//List of socket receive actions
export const SOCKET_RECEIVE_EVENTS = {
    CONNECT_ERROR: "connect_error",
    GAME_DETAILS: "gameDetails",
    PLAYER_ANSWERED: "playerAnswered",
    START_COUNTDOWN: "startCountdown",
    ROUND_COUNTDOWN: "roundCountdown",
    ROUND_STARTS: "roundStarts",
    UPDATE_QUESTIONS: "updateQuestions",
    ROUND_ENDS: "roundEnds",
    GAME_ENDS: "gameEnds",

    HANDLE_ERROR: "handleError",
    VIDEO_DATA: "videoData",
    UPDATE_STATUS: "updateStatus",
    WAITING_COUNTDOWN: "waitingCountdown",
};

export const GAME_STATUS = {
    WAITING: "waiting",
    COUNTDOWN: "countdown",
    QUESTIONS: "questions",
    END: "end",
};
