import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Typography, Paper } from "@mui/material";

const ErrorPage = () => {
    const error = useSelector(({ message }) => message.error);

    return (
        <Box
            sx={{
                display: "flex",
                height: "100vh",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Paper
                sx={{
                    padding: "2rem",
                    backgroundColor: "black",
                    color: "#fff",
                    border: "3px solid #00b3e2",
                }}
            >
                <Typography variant="h5" align="center">
                    {error}
                </Typography>
            </Paper>
        </Box>
    );
};

export default ErrorPage;
